<template>
  <b-card
    no-body
  >
    <b-card-body
      title="PIN"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-input
            id="adresa_pin"
            v-model="adresaPin"
            :placeholder="$t('Adresa za PIN')"
          />
          <small
            v-if="formErrors['adresa_pin']"
            class="text-danger"
          >
            {{ $t(formErrors["adresa_pin"][0]) }}
          </small>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-input
            id="pin"
            v-model="pin"
            :placeholder="$t('PIN')"
          />
          <small
            v-if="formErrors['pin']"
            class="text-danger"
          >
            {{ $t(formErrors["pin"][0]) }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="PosaljiPin"
          >
            {{ $t("Pošalji PIN") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-html="responsePin"
      />
    </b-card-body>
    <b-card-body
      :title="$t('Račun')"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-input
            id="adresa_racun"
            v-model="adresaRacun"
            :placeholder="$t('Adresa za kreiranje računa')"
          />
          <small
            v-if="formErrors['adresa_racun']"
            class="text-danger"
          >
            {{ $t(formErrors["adresa_racun"][0]) }}
          </small>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-form-textarea
            id="podaci_za_racun"
            v-model="podaciZaRacun"
            :placeholder="$t('Podaci za kreiranje računa')"
          />
          <small
            v-if="formErrors['podaci_za_racun']"
            class="text-danger"
          >
            {{ $t(formErrors["podaci_za_racun"][0]) }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="PosaljiRacun"
          >
            {{ $t("Pošalji račun") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-html="responseRacun"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: '',
      data: {},
      hideHeader: true,
      formErrors: [],
      adresaPin: '',
      pin: '',
      adresaRacun: '',
      podaciZaRacun: '',
      responsePin: '',
      responseErrorPin: '',
      responseRacun: '',
      responseErrorRacun: '',
    }
  },
  methods: {
    PosaljiPin() {
      const podaci = {
        address: this.adresaPin,
        pin: this.pin,
      }
      this.$http.post('/autoskole-administratori/esir-pin', podaci)
        .then(response => {
          this.responsePin = response.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.adresaPin,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.responsePin = error
        })
    },
    PosaljiRacun() {
      const podaci = {
        address: this.adresaRacun,
        body: this.podaciZaRacun,
      }
      this.$http.post('/autoskole-administratori/esir-kreiraj-racun', podaci)
        .then(response => {
          this.responseRacun = response.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.adresaPin,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.responseRacun = error
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss'
</style>
